import React, { useEffect, useState } from 'react';
import classes from './RestaurantSidebar.module.scss';
import { useTranslation } from 'react-i18next';
import { logout } from '@lo/shared/services/auth';
import classNames from 'classnames';
import { ADD_TO_STORAGE, AVAILABLE_LANGUAGES, GET_FROM_STORAGE, REMOVE_FROM_STORAGE } from '@lo/shared/constants';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';
import { ChainRestaurantSelector } from './ChainRestaurantSelector/ChainRestaurantSelector';
import useChainRestaurants from '@lo/shared/hooks/useChainRestaurants';
import { RestaurantInChainType } from '@lo/shared/types/restaurantInChainType';
import LocationPinRestaurant from '@jet-pie/react/esm/icons/LocationPinRestaurant';
import LogOut from '@jet-pie/react/esm/icons/LogOut';
import { languageFlagsMap } from '../../common/js/languageFlags';
import RestaurantLogo from '../RestaurantLogo/RestaurantLogo';
import { IconButton, SideSheet } from '@jet-pie/react';
import { Close } from '@jet-pie/react/esm/icons';
import { useRestaurantStatus } from '@lo/shared/hooks/useRestaurantStatus';
import { removeDeviceApi } from '@lo/shared/api/devices';
import queryClient from '@lo/shared/services/query/queryClient';
import { RestaurantModel } from '@lo/shared/models';
import { useOrdersStoreActions } from '@lo/shared/store/orders';
import { toast } from 'react-toastify';

type RestaurantSidebarProps = {
    isOpen: boolean;
    restaurant: RestaurantModel;
    toggleSidebar: () => void;
};

const RestaurantSidebar: React.FC<RestaurantSidebarProps> = ({ isOpen, toggleSidebar, restaurant }) => {
    const { t, i18n } = useTranslation();
    const { isClosed: isRestaurantClosed } = useRestaurantStatus();
    const { data: chainRestaurantData } = useChainRestaurants();
    const actions = useOrdersStoreActions();
    const [currentLanguage, setCurrentLanguage] = useState(defineStorageMethod(GET_FROM_STORAGE, 'lang') || 'en');

    const [showChainSelector, toggleShowChainSelector] = useState(false);
    const [showLanguagesSelector, toggleShowLanguagesSelector] = useState(false);

    const restaurantStatus = !isRestaurantClosed
        ? t('orders.live_orders_settings.restaurant.open')
        : t('orders.live_orders_settings.restaurant.closed');

    const hasChainRestaurants = chainRestaurantData && chainRestaurantData.length > 0;

    useEffect(() => {
        if (!isOpen) {
            toggleShowChainSelector(false);
            toggleShowLanguagesSelector(false);
        }
    }, [isOpen]);

    const handleRestaurantChange = (id: number) => {
        const selectedRestaurantId = restaurant.id;

        if (id !== selectedRestaurantId) {
            const selectedRestaurant = chainRestaurantData?.find((item) => item.id === id) as RestaurantInChainType;

            defineStorageMethod(ADD_TO_STORAGE, 'selectedRestaurantId', selectedRestaurant.reference);

            // disable notifications
            const fcmToken = defineStorageMethod(GET_FROM_STORAGE, 'fcmToken');
            if (fcmToken) {
                defineStorageMethod(REMOVE_FROM_STORAGE, 'fcmToken');
                removeDeviceApi(fcmToken);
            }

            actions.closeOrderDetails();

            queryClient.invalidateQueries({ queryKey: ['restaurant'] });

            toast.dismiss();
        }

        toggleShowChainSelector(false);
    };

    const handleLanguageChange = (locale: string): void => {
        toggleShowLanguagesSelector(false);
        setCurrentLanguage(locale);
        i18n.changeLanguage(locale);
    };

    const LanguageFlagIcon = languageFlagsMap[currentLanguage];

    return restaurant ? (
        <SideSheet
            id="restaurantSideSheet"
            data-testid="restaurant-sidebar"
            isOpen={isOpen}
            onShowSideSheet={toggleSidebar}
            title={t('orders.live_orders_settings.settings_page.restaurant') ?? 'Restaurant'}
            orientation="right"
            width="342px"
            hideOnOutsideClick
            hideHeader
            backdrop
        >
            <div className={classes.header}>
                <IconButton icon={<Close />} variant="ghost-tertiary" onClick={toggleSidebar} size="small" />
                <RestaurantLogo restaurant={restaurant} />
            </div>

            <div className={classes.container}>
                {hasChainRestaurants && (
                    <ChainRestaurantSelector
                        onChange={handleRestaurantChange}
                        isOpen={showChainSelector}
                        close={() => toggleShowChainSelector(false)}
                    />
                )}
                <LanguageSelector
                    onChange={handleLanguageChange}
                    isOpen={showLanguagesSelector}
                    close={() => toggleShowLanguagesSelector(false)}
                />

                <div>
                    <div className={classes.mainInfo}>
                        <div className={classes.name}>{restaurant.name}</div>
                        <div className={classes.reference}>{restaurant.reference}</div>
                    </div>
                    {restaurantStatus && (
                        <div className={classes.statusSwitcher}>
                            <div>
                                {t('orders.live_orders_settings.restaurant.restaurant_status')}:
                                <span className={classes.currentStatus}>{restaurantStatus}</span>
                            </div>
                        </div>
                    )}
                </div>

                <div>
                    {hasChainRestaurants && (
                        <button
                            className={classNames(classes.menuItem, classes.withRightArrow)}
                            onClick={() => toggleShowChainSelector(true)}
                            data-testid="chain-restaurant-switcher"
                        >
                            <div className={classes.menuItemIcon}>
                                <LocationPinRestaurant width={20} height={20} />
                            </div>
                            {t('orders.live_orders_navigation.chains.select_restaurant')}
                        </button>
                    )}

                    {hasChainRestaurants && <div className={classes.divider} />}

                    <button
                        className={classNames(classes.menuItem, classes.withRightArrow, classes[currentLanguage])}
                        onClick={() => toggleShowLanguagesSelector(true)}
                        data-testid="language-switcher"
                    >
                        <LanguageFlagIcon className={classes.languageItemFlag} />
                        {AVAILABLE_LANGUAGES[currentLanguage]}
                    </button>

                    <div className={classes.divider} />

                    <button className={classes.menuItem} onClick={() => logout()} data-testid="logout-button">
                        <div className={classes.menuItemIcon}>
                            <LogOut width={20} height={20} />
                        </div>
                        {t('orders.live_orders_navigation.menu.logout')}
                    </button>
                </div>
            </div>
        </SideSheet>
    ) : null;
};

export default RestaurantSidebar;
